#Produtos .modalProduto .title {
  text-align: center;
  display: block;
  margin: 0 auto;
}
#Produtos .modalProduto .infos {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}
#Produtos .modalProduto .infos article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px;
  border: solid 1.5px #015249;
  border-radius: 5px;
  margin: 4px 1px;
}
#Produtos .modalProduto .vendas {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2px 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  margin: 5px 0;
}
#Produtos .modalProduto .vendas .venda {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 5px;
  border: #015249 1.5px solid;
  border-radius: 5px;
  text-align: center;
}
#Produtos .modalProduto button {
  margin: 1px;
}
@media (max-width: 700px) {
  #Produtos .modalProduto .infos {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  #Produtos .modalProduto .vendas {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
@media (max-width: 500px) {
  #Produtos .modalProduto .infos {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  #Produtos .modalProduto .vendas {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}