#main-footer {
  /* min-height: calc(min-content + 10px); */
  background: #015249;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  /* align-items: center; */
  padding: 10px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin: 0px;
}

a {
  text-decoration: none;
}

button {
  color: #fff;
}

button:hover {
  color: #015249;
}

.menu button {
  font-size: 20px;
  margin: 3px;
}