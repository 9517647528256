#Produtos .produtoCreate .title {
  text-align: center;
  margin: 0 auto;
  padding: 15px;
}
#Produtos .produtoCreate section {
  width: 90%;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 3px 1fr 3px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3px;
}
#Produtos .produtoCreate section article {
  border: #015249 1.5px solid;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  background-color: #fff;
}
#Produtos .produtoCreate section article#porcentagem {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
#Produtos .produtoCreate section article#porcentagem input#porcentagem {
  width: 70%;
}
#Produtos .produtoCreate section article#porcentagem input#porcentagem::after {
  content: "%";
}
#Produtos .produtoCreate section article.botoes {
  -ms-grid-column: 3;
  grid-column: 3;
}
#Produtos .produtoCreate section article textarea {
  width: 90%;
  max-width: 400px;
  min-height: 60px;
  margin-top: 5px;
}
@media (max-width: 900px) {
  #Produtos .produtoCreate section {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  #Produtos .produtoCreate section article.botoes {
    -ms-grid-column: 2;
    grid-column: 2;
  }
}
@media (max-width: 800px) {
  #Produtos .produtoCreate section {
    width: 100%;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  #Produtos .produtoCreate section article .botoes {
    -ms-grid-column: 2;
    grid-column: 2;
  }
  #Produtos .produtoCreate section article#porcentagem {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
@media (max-width: 650px) {
  #Produtos .produtoCreate section {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  #Produtos .produtoCreate section article.botoes {
    -ms-grid-column: 1;
    grid-column: 1;
  }
}