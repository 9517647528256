.perfilPage {
  margin: 0 auto;
}
.perfilPage section {
  background-color: #fff;
  border: #015249 solid 1.5px;
  border-radius: 5px;
  width: 90%;
  margin: 10px auto;
  padding: 15px;
  -webkit-box-shadow: #929292 2px 2px 2px;
          box-shadow: #929292 2px 2px 2px;
}
.perfilPage section.util {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 5px;
}
.perfilPage section article {
  padding: 5px;
}