.modal {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.394);
  z-index: 20;
  padding: 10px 10px;
  min-height: 100vh;
  opacity: 0;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-animation: animeTop 0.3s forwards;
          animation: animeTop 0.3s forwards;
}
.modal .container {
  opacity: 0;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-animation: animeTop 0.3s forwards;
          animation: animeTop 0.3s forwards;
  overflow-y: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  width: 90%;
  max-height: 98vh;
  margin-bottom: 10px;
}
.modal .container .close {
  position: relative;
  float: right;
  border: none;
  padding: 0px;
  top: -20px;
  right: 0;
  margin: 10px 0 0 0;
}
.modal .container .close::before, .modal .container .close::after {
  content: " ";
  width: 2.5px;
  height: 24px;
  background-color: #015249;
  position: absolute;
}
.modal .container .close::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.modal .container .close::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.modal .container .close:hover {
  background-color: transparent;
}

@media (max-width: 650px) {
  .modal {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .modal .container {
    max-height: 90vh;
    width: 95%;
  }
  .modal .container .children {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 450px) {
  .modal .container {
    width: 98%;
  }
  .modal .container .children {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}