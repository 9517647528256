@charset "UTF-8";
#vendas {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  gap: 2px;
}
#vendas input {
  display: none;
}
#vendas input:checked ~ .tab-content {
  display: -ms-grid;
  display: grid;
}
#vendas input:checked ~ .tab-label h1::before {
  content: "ᐁ";
}
#vendas .acordeonVendas {
  opacity: 0;
  -webkit-transform: translateX(-35px);
          transform: translateX(-35px);
  -webkit-animation: animeLeft 0.3s forwards;
          animation: animeLeft 0.3s forwards;
  background-color: white;
  border: #015249 1.5px solid;
  border-radius: 5px;
  margin: 0 auto;
}
#vendas .acordeonVendas .tab-label {
  text-align: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 7fr 15px 2fr 15px 1fr 15px 1fr 15px 1.5fr;
  grid-template-columns: 7fr 2fr 1fr 1fr 1.5fr;
  gap: 15px;
  padding: 10px;
}
#vendas .acordeonVendas .tab-label h1 {
  text-align: start;
}
#vendas .acordeonVendas .tab-label h1::before {
  content: "ᐅ";
  font-size: 20px;
  padding: 5px;
}
#vendas .acordeonVendas .tab-content {
  gap: 3px;
  -ms-grid-columns: 4fr 3px 1fr 3px 1fr;
  grid-template-columns: 4fr 1fr 1fr;
  border-top: #015249 1.5px solid;
  padding: 5px;
  display: none;
  text-align: right;
}
#vendas .acordeonVendas .tab-content .del {
  -ms-grid-column: 3;
  grid-column: 3;
}

@media (max-width: 750px) {
  #vendas {
    width: 98%;
    margin: 0 auto;
  }
  #vendas .acordeonVendas .tab-label {
    -ms-grid-columns: 7fr 2fr 1fr;
    grid-template-columns: 7fr 2fr 1fr;
  }
  #vendas .acordeonVendas .tab-label .data {
    -ms-grid-column: 2;
    grid-column: 2;
  }
  #vendas .acordeonVendas .tab-label .total {
    -ms-grid-column: 3;
    grid-column: 3;
  }
  #vendas .acordeonVendas .tab-content {
    -ms-grid-columns: 2.5fr 1fr 1fr;
    grid-template-columns: 2.5fr 1fr 1fr;
  }
}