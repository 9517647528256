#newVendas {
  width: 100vw;
  height: 100vh;
  display: -ms-grid;
  display: grid;
      grid-template-areas: "nav header header " "nav main main " "nav footer footer ";
  -ms-grid-rows: 12vh auto 10vh;
  grid-template-rows: 12vh auto 10vh;
  -ms-grid-columns: 170px auto;
  grid-template-columns: 170px auto;
}
#newVendas #nav {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
  padding-top: 50px;
  grid-area: nav;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #015249;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#newVendas #nav button {
  margin: 0 auto;
}
#newVendas main {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  height: auto;
  grid-area: main;
}
#newVendas footer {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  grid-area: footer;
  height: 10vh;
  background-color: sienna;
}