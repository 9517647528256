#mainPage {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 20px;
}
#mainPage section .title {
  font-size: 2rem;
}
#mainPage section div.grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 5px 1fr 5px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}
#mainPage section div.grid article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  border: 2px solid #015249;
  border-radius: 5px;
  background-color: #fff;
  min-height: 80px;
  -webkit-box-shadow: #929292 2px 2px 2px;
          box-shadow: #929292 2px 2px 2px;
}
#mainPage section div.grid article p {
  font-size: 1.3rem;
  padding: 2.5px;
  margin: 0;
}

@media (max-width: 650px) {
  #mainPage section div.grid {
    -ms-grid-columns: 1fr 1px 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 1px;
  }
}
@media (max-width: 500px) {
  #mainPage {
    width: 98%;
  }
  #mainPage section div.grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}