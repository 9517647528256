#filtroComponent {
  background-color: #fff;
  border: #015249 1.5px solid;
  margin: 0 auto;
  width: 70%;
  margin-top: -15px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 2px 2px 2px #929292;
          box-shadow: 2px 2px 2px #929292;
}
#filtroComponent select {
  padding: 5px;
}
#filtroComponent div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#filtroComponent div * {
  margin: 3px;
}

@media (max-width: 750px) {
  #filtroComponent {
    width: 85%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (max-width: 600px) {
  #filtroComponent {
    width: 98%;
    min-width: 250px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}