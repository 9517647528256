* {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

body,
#root {
  font-size: 60%;
  background-color: #c7c7c7;
  color: #333;
  font-size: 0.9rem;
}

select {
  padding: 5px;
}

button {
  padding: 7px;
  background: none;
  border-radius: 5px;
  cursor: pointer;
  border: 1px #fff solid;
  color: #fff;
  background-color: #015249;
}
button:hover {
  color: #015249;
  border: 1px #015249 solid;
  background-color: #fff;
}
button.rev {
  padding: 7px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  border: 1px #015249 solid;
  color: #015249;
}
button.rev:hover {
  border: 1px #015249 solid;
  background-color: #015249;
  color: #fff;
}

select {
  font-family: "Lato", sans-serif;
}

/* 

#error,
.error {
    text-align: center;
    color: rgb(165, 8, 8);
}

p {
    position: relative;
    margin: 10px;
}

.title {
    text-align: center;
    margin: 15px;
    font-size: 40px;
}



.content {
    background: $secundary-color;
    width: 90%;
    text-align: center;
    margin: 0px auto;
    margin-bottom: 20px;
    padding: 10px;
}

.content input {
    padding: 5px;
    margin: 10px;
}

.content button {
    margin: 10px;
}

 */