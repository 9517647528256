#Clientes #debitCreate {
  text-align: center;
  width: 98%;
  margin: 5px auto;
  border: #015249 1.5px solid;
  border-radius: 5px;
  -webkit-box-shadow: 2px 2px 2px #929292;
          box-shadow: 2px 2px 2px #929292;
}
#Clientes #debitCreate h1 {
  padding: 10px;
}
#Clientes #debitCreate form {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
#Clientes #debitCreate form button {
  width: 100px;
}