.RelatoriosPage span {
  background-color: #015249;
  border: #015249 1px solid;
  margin: 5px 5px 5px 0px;
  border-radius: 5px 5px 5px 0px;
  font-size: 1.3rem;
  padding: 5px;
  color: #fff;
}
.RelatoriosPage section {
  border: #015249 1px solid;
  border-radius: 0 5px 5px 5px;
  background-color: #fff;
  padding: 10px;
  margin-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}