.nav-float {
  color: black;
  font-size: 40px;
  padding: 10px;
  position: fixed;
  bottom: 10px;
  right: 5px;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.nav-float a {
  margin: 0;
}

@media (max-width: 600px) {
  .nav-float {
    position: static;
    display: none;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #fff;
    width: 98%;
    margin: 5px auto;
    gap: 2px;
    border: #015249 1.7px solid;
    margin-top: -5px;
    border-radius: 5px;
  }
}