#filtroComponent {
  opacity: 0;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-animation: animeTop 0.3s forwards;
          animation: animeTop 0.3s forwards;
  background-color: #fff;
  border: #015249 1.5px solid;
  margin: 0 auto;
  width: 70%;
  margin-top: -15px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 2px 2px 2px #929292;
          box-shadow: 2px 2px 2px #929292;
}
#filtroComponent select {
  padding: 5px;
}
#filtroComponent div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#filtroComponent div * {
  margin: 3px;
}

@media (max-width: 700px) {
  #filtroComponent {
    width: 98%;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    gap: 5px;
  }
}