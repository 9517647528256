#Clientes .ClienteCreate .title {
  text-align: center;
  margin: 0 auto;
  padding: 15px;
}
#Clientes .ClienteCreate #buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-grid-column: 3;
  grid-column: 3;
  border: #015249 2px solid;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  background-color: #fff;
}
#Clientes .ClienteCreate .grid {
  width: 90%;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 3px 1fr 3px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3px;
}
#Clientes .ClienteCreate .grid article {
  border: #015249 1.5px solid;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  background-color: #fff;
}
#Clientes .ClienteCreate .grid article input {
  width: 75%;
  padding: 5px;
}
#Clientes .ClienteCreate .grid article.botoes {
  -ms-grid-column: 3;
  grid-column: 3;
}
@media (max-width: 900px) {
  #Clientes .ClienteCreate .grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  #Clientes .ClienteCreate .grid #buttons {
    -ms-grid-column: 2;
    grid-column: 2;
  }
}
@media (max-width: 700px) {
  #Clientes .ClienteCreate .grid {
    width: 100%;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 650px) {
  #Clientes .ClienteCreate .grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  #Clientes .ClienteCreate .grid #buttons {
    -ms-grid-column: 1;
    grid-column: 1;
  }
}