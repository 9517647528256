.notifyes {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: fixed;
  right: 5px;
  top: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 3px;
  overflow: hidden;
  border-radius: 5px;
  z-index: 50;
}
.notifyes .notify {
  opacity: 0;
  -webkit-transform: translateX(30vw);
          transform: translateX(30vw);
  z-index: 50;
  width: 30vw;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  -webkit-box-shadow: #929292 2px 2px 2px;
          box-shadow: #929292 2px 2px 2px;
  border: #015249 solid 2px;
  -webkit-animation: appenNotfy 0.5s forwards;
          animation: appenNotfy 0.5s forwards;
}
@media (max-width: 700px) {
  .notifyes .notify {
    width: 50vw;
  }
}

@-webkit-keyframes appenNotfy {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}

@keyframes appenNotfy {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}