#Clientes .modalCliente .title {
  text-align: center;
  display: block;
  margin: 0 auto;
}
#Clientes .modalCliente .alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 5px auto;
}
#Clientes .modalCliente .alert h1 {
  background-color: #ffe70f5a;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  border: #ffe70f solid 1.5px;
  text-align: center;
  -webkit-animation: alertframe 1.2s linear infinite;
          animation: alertframe 1.2s linear infinite;
}
#Clientes .modalCliente .infos {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}
#Clientes .modalCliente .infos article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px;
  border: solid 1.5px #015249;
  border-radius: 5px;
  margin: 4px 1px;
}
#Clientes .modalCliente .vendas {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2px 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  margin: 5px 0;
}
#Clientes .modalCliente .vendas .venda {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 5px;
  border: #015249 1.5px solid;
  border-radius: 5px;
  text-align: center;
}
#Clientes .modalCliente button {
  margin: 1px;
}
@media (max-width: 750px) {
  #Clientes .modalCliente .infos {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  #Clientes .modalCliente .vendas {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 600px) {
  #Clientes .modalCliente .infos {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  #Clientes .modalCliente .vendas {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}