#Produtos .itens {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 3px 1fr 3px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3px;
}
#Produtos .itens article {
  opacity: 0;
  -webkit-transform: translateX(-35px);
          transform: translateX(-35px);
  -webkit-animation: animeLeft 0.3s forwards;
          animation: animeLeft 0.3s forwards;
  -webkit-box-shadow: #929292 2px 2px 2px;
          box-shadow: #929292 2px 2px 2px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr 10px auto 10px 0.7fr;
  grid-template-rows: 1fr auto 0.7fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  background: #fff;
  border: 1.8px solid #015249;
  border-radius: 5px;
  gap: 10px;
  padding: 10px;
}
#Produtos .itens article > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
#Produtos .itens article > *:nth-child(2) {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}
#Produtos .itens article > *:nth-child(3) {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
}
#Produtos .itens article h1 {
  -ms-grid-row: 1;
  grid-row: 1;
}
#Produtos .itens article div {
  -ms-grid-row: 2;
  grid-row: 2;
}
#Produtos .itens articlebuttom {
  -ms-grid-row: 4;
  grid-row: 4;
  -ms-grid-column: 1;
  grid-column: 1;
  grid-area: buttom;
  width: 90%;
}
#Produtos .itens article#criar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#Produtos .itens article#criar div {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #c7c7c7;
  padding: 15px;
  border-radius: 50%;
  text-align: center;
}
#Produtos .itens article#criar div h1 {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  margin: 0;
  font-size: 270%;
  height: 50px;
  width: 50px;
}
#Produtos .itens .modal {
  display: frex;
}
@media (max-width: 700px) {
  #Produtos .itens {
    width: 98%;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 400px) {
  #Produtos .itens {
    width: 98%;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}