input {
  border: 1.3px solid #4e4e4e;
  border-radius: 3px;
  padding: 5px;
  margin: 0 10px 10px 10px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  width: 90%;
  margin: 5px;
}
input.w50 {
  width: 50%;
}
input.w20 {
  width: 20%;
}
input.center {
  margin: 0 auto;
}

label {
  font-size: 15px;
  margin: 5px;
  margin-bottom: 5px;
  padding: 5px;
}