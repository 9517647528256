#Clientes .modalClienteAtualizar h1 {
  text-align: center;
}
#Clientes .modalClienteAtualizar .error {
  margin: 0 auto;
  text-align: center;
  color: red;
}
#Clientes .modalClienteAtualizar .infos {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}
#Clientes .modalClienteAtualizar .infos article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 5px;
  border: solid 1.5px #015249;
  border-radius: 5px;
  margin: 4px 1px;
}
#Clientes .modalClienteAtualizar .vendas {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2px 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  margin: 5px 0;
}
#Clientes .modalClienteAtualizar .vendas .venda {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 5px;
  border: #015249 1.5px solid;
  border-radius: 5px;
  text-align: center;
}
#Clientes .modalClienteAtualizar button {
  margin: 1px;
}
@media (max-width: 950px) {
  #Clientes .modalClienteAtualizar .infos {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  #Clientes .modalClienteAtualizar .vendas {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 600px) {
  #Clientes .modalClienteAtualizar .infos {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  #Clientes .modalClienteAtualizar .vendas {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}