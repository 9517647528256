#loading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* height: 100vh; */
  font-size: 0.7rem;
  margin: 0 auto;
}
#loading h1 {
  margin-left: 1rem;
}
#loading h1::after {
  content: "   ";
  margin-left: 5px;
  -webkit-animation: infinite 2.5s dot;
          animation: infinite 2.5s dot;
}
#loading .spinner {
  height: 30px;
  width: 30px;
  border: 2.5px solid #00000026;
  /* border: 8px rgba(0, 0, 0, 0.459); */
  border-left-color: #015249;
  border-radius: 50%;
  -webkit-animation: spin 1.8s linear infinite;
          animation: spin 1.8s linear infinite;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dot {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
}
@keyframes dot {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
}