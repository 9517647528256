#newVendas .header {
  padding: 5px;
  margin: 0;
  background-color: #fff;
  grid-area: header;
  text-align: center;
  border-radius: 5px;
}
#newVendas .header form {
  padding: 5px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3fr 3fr 3fr 1fr;
  grid-template-columns: 3fr 3fr 3fr 1fr;
}

@media (max-width: 750px) {
  #vendasComponent {
    max-width: 98%;
  }

  #vendasComponent form {
    padding: 10px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  #vendaButtom {
    /* grid-column: 2; */
    margin: 10px;
  }
}