@-webkit-keyframes animeLeft {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}
@keyframes animeLeft {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}
@-webkit-keyframes animeTop {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}
@keyframes animeTop {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}