header#main-header {
  /* min-height: calc(min-content + 10px); */
  background: #015249;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 25px 15px;
  margin-bottom: 5px;
}

a {
  text-decoration: none;
}

button {
  color: #fff;
}

button:hover {
  color: #015249;
}

.menu {
  display: inline;
  text-align: right;
}

.menu button {
  font-size: 20px;
  margin: 3px;
}

a {
  color: #fff;
}