#vendasComponent {
  padding: 5px;
  max-width: 90%;
  margin: 5px auto;
  margin-top: -15px;
  background-color: #fff;
  text-align: center;
  border: #015249 solid 1.5px;
  border-radius: 5px;
  opacity: 0;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-animation: animeTop 0.3s forwards;
          animation: animeTop 0.3s forwards;
}
#vendasComponent h2 {
  padding: 10px;
}
#vendasComponent form {
  padding: 5px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3fr 3fr 3fr 1fr;
  grid-template-columns: 3fr 3fr 3fr 1fr;
}

@media (max-width: 750px) {
  #vendasComponent {
    max-width: 98%;
  }

  #vendasComponent form {
    padding: 10px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  #vendaButtom {
    /* grid-column: 2; */
    margin: 10px;
  }
}