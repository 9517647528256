@charset "UTF-8";
#vendas {
  max-width: 90%;
  margin: 5px auto;
  margin-bottom: 10px;
}
#vendas .date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-animation: animeTop 0.3s forwards;
          animation: animeTop 0.3s forwards;
  margin-top: 5px;
}
#vendas .date h1 {
  color: #fff;
  border-radius: 5px 5px 0 5px;
  display: block;
  font-size: 16px;
  padding: 7.5px;
  background-color: #015249;
}
#vendas section {
  -webkit-box-shadow: #929292 2px 2px 2px;
          box-shadow: #929292 2px 2px 2px;
  border: #015249 solid 1px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 4fr 1.5fr 1fr 1fr 1.5fr;
  grid-template-columns: 4fr 1.5fr 1fr 1fr 1.5fr;
}
#vendas section * {
  text-align: center;
}
#vendas section * h1 {
  text-align: left;
  padding: 10px;
}
#vendas section *::before {
  content: "ᐒ";
  font-size: 1rem;
  padding: 10px;
}

#venda .title {
  text-align: center;
  padding: 10px;
}
#venda #filtroVendas {
  border: #015249 solid 1px;
  margin: 0 auto;
  border-radius: 5px;
  width: 90%;
  background-color: white;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

@media (max-width: 750px) {
  #vendas {
    max-width: 97%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  #venda #filtroVendas {
    width: 98%;
  }

  #vendas section {
    border: #015249 1.5px solid;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 4fr 1fr 1fr;
    grid-template-columns: 4fr 1fr 1fr;
  }

  #vendas section .data {
    -ms-grid-column: 2;
    grid-column: 2;
  }

  #vendas section {
    -ms-grid-column: 3;
    grid-column: 3;
  }

  #vendas section h1::before {
    content: "ᐒ";
    font-size: 1rem;
    padding: 5px 5px 5px 0px;
  }
}