#debtsView .title {
  padding: 5px;
}
#debtsView .resumo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -webkit-box-shadow: #929292 2px 2px 2px;
          box-shadow: #929292 2px 2px 2px;
  padding: 10px;
  text-align: center;
  border: #015249 1.5px solid;
  border-radius: 5px;
  margin-bottom: 10px;
}
#debtsView .resumo p {
  font-size: 1.2rem;
}
#debtsView .debts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-grid-columns: 1fr 5px 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
#debtsView .debts section {
  cursor: pointer;
  width: 500px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 1;
      flex-basis: 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#debtsView .debts section.open article {
  -webkit-box-shadow: none;
          box-shadow: none;
}
#debtsView .debts section.open .options {
  -webkit-box-shadow: #929292 2px 2px 2px;
          box-shadow: #929292 2px 2px 2px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3.5fr 1fr 1fr;
  grid-template-columns: 3.5fr 1fr 1fr;
}
#debtsView .debts section.open .options button {
  padding: 7px;
}
#debtsView .debts section.open .options button.update {
  -ms-grid-column: 3;
  grid-column: 3;
}
#debtsView .debts section.open .options button.delete {
  -ms-grid-column: 2;
  grid-column: 2;
}
#debtsView .debts section article {
  padding: 10px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 4fr 10px 1fr;
  grid-template-columns: 4fr 1fr;
  gap: 10px;
  text-align: center;
  border: #015249 1.5px solid;
  border-radius: 5px;
  -webkit-box-shadow: #929292 2px 2px 2px;
          box-shadow: #929292 2px 2px 2px;
  padding: 10px 2px 10px 10px;
}
#debtsView .debts section article .infos {
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-right: 10px;
  gap: 20px;
}
#debtsView .debts section article h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
}
#debtsView .debts section article p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 600px) {
  #debtsView .debts section article {
    -ms-grid-columns: 3fr 1fr;
    grid-template-columns: 3fr 1fr;
  }
  #debtsView .debts section article .infos {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}
#debtsView .debts section .options {
  margin-top: -1.5px;
  display: none;
  border: #015249 1.5px solid;
  border-top: none;
  border-radius: 0px 0px 5px 5px;
  padding: 2px;
}
@media (max-width: 700px) {
  #debtsView .debts {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}