.loginPage,
.registerPage {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 10vw auto 10vw;
  grid-template-columns: 10vw auto 10vw;
  padding: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100vh;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.659)), to(rgba(0, 0, 0, 0.659))), url("./fundo.png") no-repeat;
  background: linear-gradient(rgba(0, 0, 0, 0.659), rgba(0, 0, 0, 0.659)), url("./fundo.png") no-repeat;
  background-size: 230vh;
  background-color: black;
}
.loginPage .container,
.registerPage .container {
  -ms-grid-column: 2;
  grid-column: 2;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
}
.loginPage .container .rev,
.registerPage .container .rev {
  margin: 5px;
}

@media (max-width: 600px) {
  .loginPage,
.registerPage {
    -ms-grid-columns: 1vw auto 1vw;
    grid-template-columns: 1vw auto 1vw;
  }
}